import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getPurchaseInformation(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .post('purchaseInformation')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settingsUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getPackConfig(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('getPackConfig')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    /// IMPORT INVENTORY EXCEL GET SETTING

    fetchInventorys(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('getAllInventorys', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updatePackConfig(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .put('updatePackConfig', queryParams)
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    updateSetting(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`updateSetting/1`, data)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    fetchCustomerList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('customer_headers/customers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('customer_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchCustomerColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('customer_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchAdjustmentList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('adjustment/adjustments', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryReportList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('inventoryreport/reports', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchStockReportList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('stockreport/reports', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //INVENTORY SETTINGS

    fetchHeaders(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('fetch_headers', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInventoryList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('inventory_headers/inventory', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //SALE SETTINGS

    fetchSaleList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('sale_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //PURCHASE SETTINGS

    fetchPurchaseList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('purchase_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchOffroadList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('offroad_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchInceptionList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('inception_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    //SALE ORDER SETTINGS

    fetchSaleOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('saleorder/orders', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    /// PURCHASE ORDER SETTINGS

    fetchPurchaseOrderList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder/orders', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchLeadList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("customer_headers/customers", { params: queryParams })
          .get('lead_headers_column', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    /********* Roles */
    /*********** */
    fetchRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('roles', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('permissions', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },
    addRole(ctx, role) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('roles/store', role)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    updateRole(payload) {
      return new Promise((resolve, reject) => {
        axiosIns
          .put(`roles/${payload.role}`, payload.permissions)
          .then((response) => {
            resolve(response);
            //    showToast("success");
          })
          .catch((error) => reject(error));
      });
    },

    getAllPurchaseQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllPurchase', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllSaleQuotes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('getAllSales', { params: queryParams })

          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    getAllPurchaseOrderQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllExpense', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchPurchaseOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('purchaseorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    getAllSaleOrderQuote(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllIncome', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchSaleOrderColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('saleorder_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllAdjustment(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllAdjustment', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchAdjustmentColumns(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('adjustment_columns', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getAllCustomers(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getAllCustomers')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchInputs(ctx) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get('getInventoryRequiredInputs')
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    setRequiredInputs(ctx, inputs) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('setRequiredInputs', inputs)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
